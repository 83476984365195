<template>
    <div>
        <!-- <card-header :header="topheaders"></card-header> -->
        <div class="mb-4">
            <v-btn text rounded color="secondary" @click="backToList()">
                <v-icon class="mr-2">{{ icons.mdiKeyboardBackspace }}</v-icon>
                Back to list
            </v-btn>
        </div>
        <v-card class="pb-12">
            <v-card-text class="px-0 my-auto py-0 mx-auto">
            </v-card-text>
            <v-container class="pt-8 pb-10">
                <v-row justify="center">
                    <v-col cols="10" class="px-0">
                        <h1 class="primary--text">
                            Create company
                        </h1>
                        <hr class="hr-l mb-4">
                    </v-col>
                    <v-col cols="11" class="new-company-single-block my-4">
                        <!-- <v-card tile class="mb-8" color="grey lighten-1"> -->
                        <v-card-title class="px-0 mb-4 text-center align-center">
                            <span>Complete technicals informations</span>
                        </v-card-title>
                        <v-form>
                            <v-text-field v-model="name" outlined dense label="Company Name" placeholder="Spacekola"
                                :error-messages="nameErrors.value" clearable :hide-details="nameErrors.isvalid"
                                type="text" @input="$v.name.$touch()" @blur="$v.name.$touch()" class="mb-2">
                            </v-text-field>

                            <v-select v-model="enterprise_type_id" dense outlined hide-details label="Type of company"
                                :items="typedata" class="mb-2"></v-select>

                            <v-text-field v-model="enterprise_type_id" label="Enter your company type" outlined dense
                                placeholder="Specify..." hide-details class="mb-2"
                                v-if="enterprise_type_id == 'Others'">
                            </v-text-field>

                            <div class="form-2col mb-2">
                                <div class="form-2col-item">
                                    <v-text-field v-model="num_employee" label="Number of employees" outlined
                                        dense placeholder="Specify the number of employees" hide-details>
                                    </v-text-field>
                                </div>
                                
                                <div class="form-2col-item">
                                    <v-text-field v-model="annual_turnover" label="Annual turnover (in USD)" outlined
                                        dense placeholder="Specify the annual turnover in USD" hide-details>
                                    </v-text-field>
                                </div>
                            </div>
                            <v-text-field v-model="email" outlined dense :hide-details="emailErrors.isvalid"
                                label="Email adress" placeholder="contact@spacekola.org" type="email"
                                :error-messages="emailErrors.value" @input="$v.email.$touch()" @blur="$v.email.$touch()"
                                clearable class="mb-2">
                            </v-text-field>

                            <vue-phone-number-input v-model="phonenumber" @update="onUpdate"
                                :default-country-code="'CM'" class="mb-2 mt-0">
                            </vue-phone-number-input>

                            <span class="error--text text-caption" v-if="phoneErrors.isvalid === false">{{
                                    phoneErrors.value
                            }}</span>
                            <v-text-field v-model="website" label="Website" outlined dense
                                placeholder="www.spacekola.org" hide-details class="mb-2">
                            </v-text-field>
                            <v-select v-model="selectedCountry" outlined dense label="Country" :items="countries"
                                item-text="name" item-value="id" class="mb-2" virtual hide-details>
                            </v-select>
                            <v-text-field v-model="address" label="Address" outlined dense hide-details
                                placeholder="Company's adress" class="text-left mb-2"></v-text-field>
                            <v-textarea v-model="about" label="Describe your company here.." outlined dense
                                placeholder="Describe your company here.." class="text-left"
                                :error-messages="aboutErrors.value" clearable :hide-details="aboutErrors.isvalid"
                                @input="$v.about.$touch()" @blur="$v.about.$touch()"></v-textarea>
                        </v-form>
                        <!-- </v-card> -->

                        <div class="my-4 d-flex">
                            <v-spacer></v-spacer>
                            <v-btn type="reset" color="error" outlined class="mx-2" @click="resetSecondStape()">
                                Reset
                            </v-btn>
                            <v-btn :loading="loader.submit" class="primary" @click="submitCreateNewCompany()">
                                Submit
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiKeyboardBackspace } from '@mdi/js'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import { Drequest } from '@/plugins/Drequest'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import DatePicker from '@/layouts/components/microcomponents/DatePicker.vue'
import CardHeader from '@/views/dashboard/components/CardHeader.vue'

export default {
    mixins: [validationMixin],

    validations: {
        name: { required, minLength: minLength(3) },
        about: { required, minLength: minLength(3) },
        email: { required, email },
    },
    data() {
        return {
            topheaders: {
                titlepage: 'MY COMPANIES',
                icon: null,
            },
            steplevel: 1,
            loader: {
                submit: false,
                isCity: false,
            },
            phoneCountry: {},
            phoneErrors: { isValid: true, value: '' },

            name: '',
            about: '',
            email: '',
            phonenumber: '',
            website: '',
            user_id: '',
            address: '',
            city: '',
            experienceyear: '',
            // application_market: '',
            enterprise_type_id: '',
            selectedCountry: 1,
            countries: [],
            found: {},
            annual_turnover: '',
            num_employee: ''
        }
    },
    components: {
        CardHeader,
        VuePhoneNumberInput,
        DatePicker,
    },

    computed: {
        nameErrors() {
            const errors = { isvalid: true, value: [] }
            if (!this.$v.name.$dirty) return errors
            !this.$v.name.minLength && errors.value.push('Name must be at most 10 characters long') && (errors.isvalid = false)
            !this.$v.name.required && errors.value.push('Name is required.') && (errors.isvalid = false)
            return errors
        },

        aboutErrors() {
            const errors = { isvalid: true, value: [] }
            if (!this.$v.about.$dirty) return errors
            !this.$v.about.minLength && errors.value.push('Please describe your company') && (errors.isvalid = false)
            !this.$v.about.required && errors.value.push('Description is required.') && (errors.isvalid = false)
            return errors
        },
        emailErrors() {
            const errors = { isvalid: true, value: [] }
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.email && errors.value.push('Must be valid e-mail') && (errors.isvalid = false)
            !this.$v.email.required && errors.value.push('E-mail is required') && (errors.isvalid = false)
            return errors
        },


    },
    methods: {
        backToList() {
            this.$router.push({ name: 'user.list.company' })
        },
        onUpdate(payload) {
            this.phoneCountry = payload
            // this.selectedCountry = (this.countries.find((element) => element.iso === this.phoneCountry.countryCode)).id;
            if (payload.isValid === true) {
                this.phoneErrors.isValid = true
                this.phoneErrors.value = ''
            }
            else {
                this.phoneErrors.isValid = false
                this.phoneErrors.value = 'Incorrect phone number'
            }
        },
        checkValidationForm() {
            if (this.nameErrors.isvalid && this.emailErrors.isvalid && this.phoneCountry.isValid) {
                return true
            }
            return false
        },
        toNextStep() {
            this.steplevel = this.steplevel + 1
        },
        goToPreviousStep() {
            this.steplevel = this.steplevel - 1
        },
        resetFirstStape() {
            this.name = ''
            this.about = ''
            this.website = ''
            // this.application_market = ''
            this.enterprise_type_id = ''
            this.annual_turnover = ''
            this.num_employee = ''
        },
        resetSecondStape() {
            this.name = ''
            this.about = ''
            this.email = ''
            this.phonenumber = ''
            this.website = ''
            this.address = ''
            // this.created_at = ''

        },

        submitCreateNewCompany() {

            this.$v.$touch()

            var bodyFormData = {
                enterprise: {
                    name: this.name,
                    website: this.website,
                    email: this.email,
                    telephone: this.phonenumber,
                    about: this.about,
                    "user.id": this.$store.getters.getUser.id,
                    nb_employee: parseInt(this.num_employee),
                    application_market: parseInt(this.annual_turnover)
                }
            }

            if (this.checkValidationForm()) {
                this.loader.submit = true;
                const detail = ''

                console.log(bodyFormData)
                Drequest.api("enterprise.create")
                    .data(bodyFormData)
                    .raw((response) => {
                        if (response.success === true) {
                            this.loader.submit = false;
                            this.$store.commit("setCompany", {
                                id: response.enterprise.id,
                                about: response.enterprise.about,
                                logo: response.enterprise.logo,
                                name: response.enterprise.name,
                                activity_sector: response.enterprise.activity_sector,
                                email: response.enterprise.email,
                                phonenumber: response.enterprise.phonenumber,
                                website: response.enterprise.website,
                                form: response.enterprise.enterprise_form,
                                type: response.enterprise.enterprise_type,
                            });
                            this.$fire({
                                type: "success",
                                text: "New company created succefully.",
                                timer: 2500
                            })
                            this.$router.push({ name: 'company.list' })
                        }
                        else {
                            this.loader.submit = false;
                            this.$fire({
                                type: "error",
                                text: "Error during the creation of your company",
                                timer: 3000
                            })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        this.loader.submit = false;
                    })
            }
        }
    },
    mounted() {
        Drequest.api(`lazyloading.country?dfilters=on&per_page=239`).get((response) => {
            if (response.success === true) {
                this.countries = response.listEntity
            }
            else {
            }
        }).catch((err) => {

        });
    },
    setup() {

        const icons = { mdiKeyboardBackspace }
        const categoridata = ['PME', 'TPE', 'Other']
        const typedata = [
            'Financial nstitution',
            'Training institution',
            'Internationnal organization',
            'Research & development organization',
            'State agency',
            'SME',
            'VSE',
            'Big company',
            'Multinational',
            'Chamber of commerce',
            'Others']

        //         const typedata = [
        // 'Institutions financières',
        // 'Institutions de formations',
        // 'Organisation internationnale',
        // 'Organisation de recherche & developpement',
        // 'Organisme d\'etat',
        // 'SME/ PME',
        // 'VSE/TPE',
        // 'Big company/ Grande Entreprise',
        // 'Multinational/ Multinationale',
        // 'Chamber of commerce/ Chambre de commerce',
        // 'Others/Autre, specify/spécifier']

        return {
            icons,
            typedata,
            categoridata,
        }
    },

}
</script>

<style lang="scss" scoped>
//@import 'vue-phone-number-input/dist/vue-phone-number-input.css';
@import '@/styles/styles.scss';

.new-company-single-block {
    border-radius: 1em;
    //border: solid 2px var(--v-subprimary-base)
}

.form-2col {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .form-2col-item{
        width: 49%;
    }
}
</style>